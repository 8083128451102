<!-- Application Main Menu  <div [hidden]="isLoading"> #filemenu="matMenuTrigger" (mouseenter)="filemenu.openMenu()"-->
<div *ngIf="!editMenuCompressed" class="menu-container">
    <!-- MAIN MENU FILE -->
    <button class="main-menu-button" mat-button [matMenuTriggerFor]="fileMenu" #menuFile="matMenuTrigger" (mousedown)="openMenu()" [disabled]="error">
        <span translate>MAIN_MENU_FILE</span>
    </button>
    <!-- MAIN MENU MODIFICA -->
    <button class="main-menu-button" mat-button [matMenuTriggerFor]="editMenu" #menuEdit="matMenuTrigger" (mousedown)="openMenu()" [disabled]="error">
        <span translate>MAIN_MENU_EDIT</span>
    </button>
    <!-- MAIN MENU VISUALIZZA -->
    <button class="main-menu-button" mat-button [matMenuTriggerFor]="viewMenu" [disabled]="error" #menuView="matMenuTrigger" (mousedown)="openMenu()">
        <span translate>MAIN_MENU_VIEW</span>
    </button>
    <!-- MAIN MENU INSERISCI -->
    <button class="main-menu-button" mat-button [matMenuTriggerFor]="insertMenu" [disabled]="error" #menuInsert="matMenuTrigger" (mousedown)="openMenu()">
        <span translate>MAIN_MENU_INSERT</span>
    </button>
    <!-- MAIN MENU STRUMENTI -->
    <button class="main-menu-button" mat-button [matMenuTriggerFor]="toolsMenu" #menuTools="matMenuTrigger" (mousedown)="openMenu()" [disabled]="error">
        <span translate>MAIN_MENU_TOOLS</span>
    </button>
    <!-- MAIN MENU FORMATO -->
    <button class="main-menu-button" mat-button [matMenuTriggerFor]="formatMenu" [disabled]="error || noSelection" #menuFormat="matMenuTrigger" (mousedown)="openMenu()">
        <span translate>MAIN_MENU_FORMAT</span>
    </button>
    <!-- MAIN MENU DISPONI -->
    <button class="main-menu-button" mat-button [matMenuTriggerFor]="arrangeMenu" [disabled]="error || noSelection" #menuArrange="matMenuTrigger" (mousedown)="openMenu()">
        <span translate>MAIN_MENU_ARRANGE</span>
    </button>
</div>
<!-- COMPRESSED MENU ROOT BUTTON-->
<div *ngIf="editMenuCompressed" class="menu-container">
    <button class="main-menu-button" mat-button [matMenuTriggerFor]="rootMenu" #menuRoot="matMenuTrigger" (mousedown)="openMenu()" [disabled]="error">
        <span>Menu</span>
    </button>
</div>

<!-- ------------------------MENU SECTION ---------------------------------------------->
<mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
 
        <!-- MAIN MENU FILE -->
        <button mat-menu-item [matMenuTriggerFor]="fileMenu" #menuFile="matMenuTrigger" [disabled]="error">
            <span translate>MAIN_MENU_FILE</span>
        </button>
        <!-- MAIN MENU MODIFICA -->
        <button mat-menu-item [matMenuTriggerFor]="editMenu" #menuEdit="matMenuTrigger" [disabled]="error">
            <span translate>MAIN_MENU_EDIT</span>
        </button>
        <!-- MAIN MENU VISUALIZZA -->
        <button mat-menu-item [matMenuTriggerFor]="viewMenu" [disabled]="error" #menuView="matMenuTrigger">
            <span translate>MAIN_MENU_VIEW</span>
        </button>
        <!-- MAIN MENU INSERISCI -->
        <button mat-menu-item [matMenuTriggerFor]="insertMenu" [disabled]="error" #menuInsert="matMenuTrigger">
            <span translate>MAIN_MENU_INSERT</span>
        </button>
        <!-- MAIN MENU STRUMENTI -->
        <button mat-menu-item [matMenuTriggerFor]="toolsMenu" #menuTools="matMenuTrigger" [disabled]="error">
            <span translate>MAIN_MENU_TOOLS</span>
        </button>
        <!-- MAIN MENU FORMATO -->
        <button mat-menu-item [matMenuTriggerFor]="formatMenu" [disabled]="error || noSelection" #menuFormat="matMenuTrigger">
            <span translate>MAIN_MENU_FORMAT</span>
        </button>
        <!-- MAIN MENU DISPONI -->
        <button mat-menu-item [matMenuTriggerFor]="arrangeMenu" [disabled]="error || noSelection" #menuArrange="matMenuTrigger">
            <span translate>MAIN_MENU_ARRANGE</span>
        </button>

</mat-menu>

<!-- MENU FILE-->
<mat-menu #fileMenu="matMenu" [overlapTrigger]="false">
           <!-- NUOVA MAPPA -->
        <div mat-menu-item (click)="newMap()">
            <mat-icon color="primary">add</mat-icon>
            <span translate>MAIN_MENU_FILE-NEW-MAP</span>
        </div>
        <!-- APRI MAPPA -->
        <!-- <div *ngIf="isGoogleUser" mat-menu-item class="custom-item" (click)="openMap()">
            <mat-icon class="custom-icon" svgIcon="folder-google-drive"></mat-icon>
            <span class="custom-span" translate>MAIN_MENU_FILE-OPEN-MAP</span>
        </div> -->
        <!-- COPIA MAPPA -->
        <div mat-menu-item (click)="copyMap()">
            <mat-icon color="primary" svgIcon="content-duplicate"></mat-icon>
            <span translate>MAIN_MENU_FILE-CREATE-A-COPY</span>
        </div>
        <div *ngIf="isGoogleUser" mat-menu-item (click)="exportMapGoogleDrive()">
            <mat-icon color="primary" svgIcon="google-drive"></mat-icon>
            <span translate>EXPORT_IN_GOOGLE_DRIVE</span>
        </div>
        <hr />
        <!-- CONDIVIDI -->
        <div mat-menu-item (click)="shareMap()">
            <mat-icon color="primary">share</mat-icon>
            <span translate>MAIN_MENU_FILE-SHARE</span>
        </div>
        <!-- DOCUMENTO GOOGLE DOC -->
        <div *ngIf="isGoogleUser && isMine && !hasGoogleDoc" mat-menu-item (click)="createOrUpdateGoogleDoc()">
            <mat-icon color="primary" fontSet="material-icons-outlined">post_add</mat-icon>
            <span translate>MAIN_MENU_FILE-CREATE-A-DOC</span>
        </div>
        <div *ngIf="isGoogleUser && isMine && hasGoogleDoc" mat-menu-item [matMenuTriggerFor]="googleDocMenu" [hidden]="error">
            <mat-icon class="icon-blue material-icons-sharp" fontSet="material-icons-filled">article</mat-icon>
            <span translate>MAIN_MENU_FILE-DOC</span>
        </div>
        <mat-menu #googleDocMenu="matMenu" [overlapTrigger]="false">
    
                <div mat-menu-item (click)="createOrUpdateGoogleDoc()" [disabled]="mapIsSaving">
                    <mat-icon color="primary">restore_page</mat-icon>
                    <span translate>MAIN_MENU_FILE-UPDATE-DOC</span>
                </div>
                <div mat-menu-item (click)="openGoogleDoc()">
                    <mat-icon color="primary" fontSet="material-icons-outlined">open_in_new</mat-icon>
                    <span translate>MAIN_MENU_FILE-OPEN-DOC</span>
                </div>
   
        </mat-menu>
        <!-- SCARICA COME -->
        <div mat-menu-item [matMenuTriggerFor]="fileExportMenu" [hidden]="error">
            <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">download</mat-icon>
            <span translate>MAIN_MENU_FILE-EXPORT</span>
        </div>
        <mat-menu #fileExportMenu="matMenu" [overlapTrigger]="false">
           
                <div mat-menu-item (click)="exportToSme()" [disabled]="mapIsSaving">
                    <mat-icon color="primary" svgIcon="sitemap"></mat-icon>
                    <span translate>MAIN_MENU_FILE-EXPORT-TO-MAP</span>
                </div>
                <mat-divider></mat-divider>
                <div mat-menu-item (click)="convertToImage(false)">
                    <mat-icon color="primary" mat-icon>image</mat-icon>
                    <span translate>MAIN_MENU_FILE-EXPORT-TO-IMAGE</span>
                </div>
                <div mat-menu-item (click)="convertToImage(true)">
                    <mat-icon color="primary" fontSet="material-icons-outlined">qr_code</mat-icon>
                    <span translate>MAIN_MENU_FILE-EXPORT-TO-IMAGE-QRCODES</span>
                </div>
                <div mat-menu-item (click)="convertToImagePdf()">
                    <mat-icon color="primary" svgIcon="file-pdf-box"></mat-icon>
                    <span translate>MAIN_MENU_FILE-EXPORT-TO-IMAGE-PDF</span>
                </div>
                <mat-divider></mat-divider>
                <div mat-menu-item (click)="convertToDocumentPdf()">
                    <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">picture_as_pdf</mat-icon>
                    <span translate>MAIN_MENU_FILE-EXPORT-TO-DOCUMENT-PDF</span>
                </div>
                <!-- <div mat-menu-item class="custom-item" (click)="convertToDocumentDocx()">
                <mat-icon class="custom-icon" fontSet="material-icons-outlined">post_add</mat-icon>
                <span class="custom-span" translate>MAIN_MENU_FILE-EXPORT-TO-DOCUMENT-DOCX</span>
            </div> -->
                <!-- SCARICA COME DOCX -->
                <div mat-menu-item [matMenuTriggerFor]="fileExportMenuDocx" [hidden]="error">
                    <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">description</mat-icon>
                    <span translate>MAIN_MENU_FILE-EXPORT-TO-DOCUMENT-DOCX</span>
                </div>
                <mat-menu #fileExportMenuDocx="matMenu" [overlapTrigger]="false">
                   
                        <div mat-menu-item (click)="convertToDocumentDocxMicrosoft()">
                            <span translate>MAIN_MENU_FILE-EXPORT-TO-DOCUMENT-DOCX-MS</span>
                        </div>
                        <div mat-menu-item (click)="convertToDocumentDocxLibreOffice()">
                            <span translate>MAIN_MENU_FILE-EXPORT-TO-DOCUMENT-DOCX-LO</span>
                        </div>
                   
                </mat-menu>
           
        </mat-menu>
        <!-- CRONOLOGIA VERSIONI -->
        <!-- <div mat-menu-item class="custom-item" (click)="openRevision()">
            <mat-icon class="custom-icon" svgIcon="calendar-clock"></mat-icon>
            <span class="custom-span" translate>MAIN_MENU_FILE-REVISION-HISTORY</span>
        </div> -->
        <hr />
        <!-- RINOMINA -->
        <div mat-menu-item (click)="renameMap()">
            <!--<mat-icon color="primary" svgIcon="rename-box"></mat-icon> ICONA PRECEDENTE-->
            <mat-icon color="primary" class="material-icons-sharp" fontSet="material-icons-filled">drive_file_rename_outline</mat-icon>
            <span translate>MAIN_MENU_FILE-RENAME</span>
        </div>
        <!-- LINGUA MAPPA -->
        <div mat-menu-item class="language-menu-item-container" [matMenuTriggerFor]="fileLanguageMenu" [hidden]="error">
            <img mat-icon class="language-icon" [src]="selectedMapLanguageIcon" />
            <span translate>MAIN_MENU_FILE_MAP-LANGUAGE</span>
        </div>
        <mat-menu class="language-menu-container" #fileLanguageMenu="matMenu" [overlapTrigger]="false" color="primary">
           
                <div mat-menu-item class="language-menu-item-container" *ngFor="let l of languagesWithFlag" (click)="changeMapLanguage(l)">
                    <img [src]="l.icon" class="language-icon" />
                    <span translate>{{l.viewValue}}</span>
                </div>
                <ng-container *ngIf="languagesNoFlag.length > 0">
                    <mat-divider></mat-divider>
                    <button class="language-menu-item-container" mat-menu-item [matMenuTriggerFor]="languageNoFlagMenu">{{'OTHER_VOICES' | translate}}</button>
                    <mat-menu class="language-menu-container" #languageNoFlagMenu="matMenu" color="primary">
                        <div *ngFor="let l of languagesNoFlag" mat-menu-item class="language-menu-item-container" (click)="changeMapLanguage(l)" [id]="l.value">
                            <img [src]="l.icon" mat-icon class="language-icon" />
                            <span translate>{{l.viewValue}}</span>
                        </div>
                    </mat-menu>
                </ng-container>
           
        </mat-menu>
        <!-- IMPOSTAZIONI -->
        <div mat-menu-item [matMenuTriggerFor]="fileSettingsMenu">
            <mat-icon color="primary" class="material-icons-sharp" fontSet="material-icons-filled">settings</mat-icon>
            <span translate>MAIN_MENU_FILE-PREFERENCES</span>
        </div>
        <mat-menu #fileSettingsMenu="matMenu" [overlapTrigger]="false">
           
                <!-- IMAGE SEARCH FILTER -->
                <div mat-menu-item [matMenuTriggerFor]="fileSettingsImagesFilterMenu">
                    <mat-icon color="primary" fontSet="material-icons-outlined">image_search</mat-icon>
                    <span translate>MAIN_MENU_FILE-PREFERENCES-IMAGE-FILTER</span>
                </div>
                <mat-menu #fileSettingsImagesFilterMenu="matMenu" [overlapTrigger]="false">
                   
                        <div mat-menu-item [disabled]="true">
                            <mat-icon color="accent">check</mat-icon>
                            <span translate>MAIN_MENU_FILE-PREFERENCES-IMAGE-FILTER-SAFESEARCH</span>
                        </div>
                        <div mat-menu-item (click)="setImageFilter('')">
                            <mat-icon color="accent" *ngIf="getImageFilter()===''">check</mat-icon>
                            <span translate>MAIN_MENU_FILE-PREFERENCES-IMAGE-FILTER-ALL</span>
                        </div>
                        <div mat-menu-item (click)="setImageFilter('=sur:f')">
                            <mat-icon color="accent" *ngIf="getImageFilter()==='=sur:f'">check</mat-icon>
                            <mat-icon color="accent" *ngIf="getImageFilter()!=='=sur:f'">uncheck</mat-icon>
                            <span translate>MAIN_MENU_FILE-PREFERENCES-IMAGE-FILTER-FREE</span>
                        </div>
                        <div mat-menu-item (click)="setImageFilter('=sur:fc')">
                            <mat-icon color="accent" *ngIf="getImageFilter()==='=sur:fc'">check</mat-icon>
                            <mat-icon color="accent" *ngIf="getImageFilter()!=='=sur:fc'">uncheck</mat-icon>
                            <span translate>MAIN_MENU_FILE-PREFERENCES-IMAGE-FILTER-FREE-COM</span>
                        </div>
                        <div mat-menu-item (click)="setImageFilter('=sur:fm')">
                            <mat-icon color="accent" *ngIf="getImageFilter()==='=sur:fm'">check</mat-icon>
                            <mat-icon color="accent" *ngIf="getImageFilter()!=='=sur:fm'">uncheck</mat-icon>
                            <span translate>MAIN_MENU_FILE-PREFERENCES-IMAGE-FILTER-FREE-MOD</span>
                        </div>
                        <div mat-menu-item (click)="setImageFilter('=sur:fmc')">
                            <mat-icon color="accent" *ngIf="getImageFilter()==='=sur:fmc'">check</mat-icon>
                            <mat-icon color="accent" *ngIf="getImageFilter()!=='=sur:fmc'">uncheck</mat-icon>
                            <span translate>MAIN_MENU_FILE-PREFERENCES-IMAGE-FILTER-FREE-MOD-COM</span>
                        </div>
                   
                </mat-menu>
                <!-- MAPS -->
                <div mat-menu-item (click)="openMapPreferences()">
                    <mat-icon color="primary" svgIcon="sitemap"></mat-icon>
                    <span translate>MAPEDIT_TITLE</span>
                </div>
                <!-- LIM -->
                <div mat-menu-item [matMenuTriggerFor]="fileLIMMenu">
                    <mat-icon color="primary" svgIcon="projector-screen"></mat-icon>
                    <span translate>MAIN_MENU_FILE-LIM-PREFERENCES</span>
                </div>
                <mat-menu #fileLIMMenu="matMenu" [overlapTrigger]="false">
                   
                        <div mat-menu-item (click)="limPreference(false)">
                            <mat-icon *ngIf="!pasteMap" color="accent">check</mat-icon>
                            <mat-icon color="primary" fontSet="material-icons-outlined">keyboard</mat-icon>
                            <span translate>MAIN_MENU_FILE-LIM-KEYBOARD</span>
                        </div>
                        <div mat-menu-item (click)="limPreference(true)">
                            <mat-icon *ngIf="pasteMap" color="accent">check</mat-icon>
                            <mat-icon color="primary" svgIcon="teach"></mat-icon>
                            <span translate>MAIN_MENU_FILE-LIM-ONLY-TOUCH</span>
                        </div>
                   
                </mat-menu>
           
        </mat-menu>
        <hr />
        <!-- STAMPA -->
        <div mat-menu-item [matMenuTriggerFor]="filePrintMenu" [hidden]="error">
            <mat-icon color="primary">print</mat-icon>
            <span translate>MAIN_MENU_FILE-PRINT</span>
        </div>
        <mat-menu #filePrintMenu="matMenu" [overlapTrigger]="false">
           
                <div mat-menu-item (click)="printMap(false)">
                    <mat-icon color="primary">print</mat-icon>
                    <!-- <span class="custom-span-only" translate>MAIN_MENU_FILE-PRINT-MAP</span> -->
                    <span translate>MAIN_MENU_FILE-PRINT-MAP</span>
                </div>
                <div mat-menu-item (click)="printMap(true)">
                    <mat-icon color="primary">print</mat-icon>
                    <span translate>MAIN_MENU_FILE-PRINT-MAP-QRCODES</span>
                </div>
                <!-- PRINT QUALITY -->
                <div mat-menu-item [matMenuTriggerFor]="fileSettingsPrintMenu">
                    <mat-icon color="primary" svgIcon="printer-settings"></mat-icon>
                    <span translate>MAIN_MENU_FILE-PREFERENCES-PRINT</span>
                </div>
                <mat-menu #fileSettingsPrintMenu="matMenu" [overlapTrigger]="false">
                   
                        <div mat-menu-item (click)="setPrintQuality(4)">
                            <mat-icon color="accent" *ngIf="getPrintQuality()===4">check</mat-icon>
                            <span [ngClass]="getPrintQuality()===4? '':'menu-item-name-spacer'" translate>MAIN_MENU_FILE-PREFERENCES-PRINT-MAXIMUM</span>
                        </div>
                        <div mat-menu-item (click)="setPrintQuality(3)">
                            <mat-icon color="accent" *ngIf="getPrintQuality()===3">check</mat-icon>
                            <span [ngClass]="getPrintQuality()===3? '':'menu-item-name-spacer'" translate>MAIN_MENU_FILE-PREFERENCES-PRINT-VERYHIGH</span>
                        </div>
                        <div mat-menu-item (click)="setPrintQuality(2)">
                            <mat-icon color="accent" *ngIf="getPrintQuality()===2">check</mat-icon>
                            <span [ngClass]="getPrintQuality()===2? '':'menu-item-name-spacer'" translate>MAIN_MENU_FILE-PREFERENCES-PRINT-HIGH</span>
                        </div>
                        <div mat-menu-item (click)="this.mapStateService.setPrintQuality(1)">
                            <mat-icon color="accent" *ngIf="getPrintQuality()===1">check</mat-icon>
                            <span [ngClass]="getPrintQuality()===1? '':'menu-item-name-spacer'" translate>MAIN_MENU_FILE-PREFERENCES-PRINT-NORMAL</span>
                        </div>
                        <div mat-menu-item (click)="setPrintQuality(0)">
                            <mat-icon color="accent" *ngIf="getPrintQuality()===0">check</mat-icon>
                            <span [ngClass]="getPrintQuality()===0? '':'menu-item-name-spacer'" translate>MAIN_MENU_FILE-PREFERENCES-PRINT-DRAFT</span>
                        </div>
                   
                </mat-menu>
           
        </mat-menu>
        <hr />
        <!-- HOME -->
        <div mat-menu-item (click)="backToHome()">
            <mat-icon color="primary" class="material-icons-sharp" fontSet="material-icons-filled">home</mat-icon>
            <span translate>HOME_TITLE</span>
        </div>
   
</mat-menu>
<!-- MENU MODIFICA -->
<mat-menu #editMenu="matMenu" [overlapTrigger]="false">
   
        <!-- ANNULLA -->
        <div mat-menu-item (click)="undo()" [disabled]="!canUndo">
            <mat-icon color="primary">undo</mat-icon>
            <span translate>MAIN_MENU_EDIT-UNDO</span>
        </div>
        <!-- RIPETI -->
        <div mat-menu-item (click)="redo()" [disabled]="!canRedo">
            <mat-icon color="primary">redo</mat-icon>
            <span translate>MAIN_MENU_EDIT-REDO</span>
        </div>
        <hr />
        <!-- TAGLIA -->
        <div mat-menu-item (click)="cut()" [disabled]="!canCopy">
            <mat-icon color="primary" fontSet="material-icons-outlined">content_cut</mat-icon>
            <span translate>MAIN_MENU_EDIT-CUT</span>
        </div>
        <!-- COPIA ALL -->
        <div *ngIf="(isSelectionPresent && !isCopyExtended) || !canCopy" mat-menu-item (click)="copySelection('all')" [disabled]="!canCopy">
            <mat-icon color="primary" fontSet="material-icons-outlined">content_copy</mat-icon>
            <span translate>MAIN_MENU_EDIT-COPY</span>
        </div>
        <!-- COPIA -->
        <div *ngIf="!isMultiSelectionPresent && isCopyExtended" mat-menu-item [matMenuTriggerFor]="copyMenu">
            <mat-icon color="primary" fontSet="material-icons-outlined">content_copy</mat-icon>
            <span translate>MAIN_MENU_EDIT-COPY</span>
        </div>
        <mat-menu #copyMenu="matMenu" [overlapTrigger]="false">
           
                <div mat-menu-item (click)="copySelection('all')">
                    <mat-icon color="primary" fontSet="material-icons-outlined">copy_all</mat-icon>
                    <span translate>MAIN_MENU_EDIT-COPY-ALL</span>
                </div>
                <div *ngIf="isTitleTextPresent" mat-menu-item (click)="copySelection('text')">
                    <mat-icon color="primary" svgIcon="alphabetical"></mat-icon>
                    <span translate>MAIN_MENU_EDIT-COPY-TEXT</span>
                </div>
                <div *ngIf="isDeepTextPresent" mat-menu-item (click)="copySelection('deep')">
                    <mat-icon color="primary" svgIcon="tooltip-text"></mat-icon>
                    <span translate>MAIN_MENU_EDIT-COPY-DEEP</span>
                </div>
                <div *ngIf="isImagePresent" mat-menu-item (click)="copySelection('image')">
                    <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">image</mat-icon>
                    <span translate>MAIN_MENU_EDIT-COPY-IMAGE</span>
                </div>
                <div *ngIf="isLinkPresent" mat-menu-item (click)="copySelection('link')">
                    <mat-icon color="primary" fontSet="material-icons-outlined">attach_file</mat-icon>
                    <span translate>MAIN_MENU_EDIT-COPY-LINK</span>
                </div>
           
        </mat-menu>
        <!-- COPIA IMMAGINE MAPPA -->
        <div mat-menu-item (click)="copyMapImage()">
            <mat-icon color="primary" fontSet="material-icons-outlined">filter</mat-icon>
            <span translate>MAIN_MENU_EDIT-COPY-MAP-IMAGE</span>
        </div>
        <!-- INCOLLA -->
        <div mat-menu-item (click)="paste()">
            <mat-icon color="primary" fontSet="material-icons-outlined">content_paste</mat-icon>
            <span translate>MAIN_MENU_EDIT-PASTE</span>
        </div>
        <!-- ELIMINA -->
        <div mat-menu-item (click)="delete()" [disabled]="!canDelete">
            <mat-icon color="primary" class="material-icons-sharp" fontSet="material-icons-filled">delete</mat-icon>
            <span translate>MAIN_MENU_EDIT-DELETE</span>
        </div>
        <hr />
        <!-- COLORE SFONDO -->
        <div mat-menu-item [matMenuTriggerFor]="colorBackgroundMenu">
            <mat-icon color="primary" mat-icon fontSet="material-icons-outlined">palette</mat-icon>
            <span translate>MAIN_MENU_EDIT-BACKGROUND</span>
        </div>
        <mat-menu class="color-picker-container" #colorBackgroundMenu="matMenu">
           
                <app-menu-color-picker [usedColorLabel]="usedColorLabel" [selectedColor]="selectedBackgroundColor" (onChangeColor)="changeBackground($event)" [colors]="backgroundColors" [showTransparent]="false"></app-menu-color-picker>
           
        </mat-menu>
   
</mat-menu>
<!-- MENU VISUALIZZA -->
<mat-menu #viewMenu="matMenu" [overlapTrigger]="false">
   
        <!-- PRESENTAZIONE -->
        <div mat-menu-item [disabled]="!canShowing" (click)="mapShowing()">
            <mat-icon color="primary" svgIcon="theater"></mat-icon>
            <span translate>SHOWING </span>
        </div>
        <hr />
        <!-- ADATTA MAPPA -->
        <div mat-menu-item (click)="zoomFit()">
            <mat-icon color="primary">settings_overscan</mat-icon>
            <span translate>MAIN_MENU_VIEW-FIT
            </span>
        </div>
        <!-- ZOOM -->
        <div mat-menu-item (click)="zoom100()">
            <mat-icon color="primary">zoom_out_map</mat-icon>
            <span translate>MAIN_MENU_VIEW-ZOOM-100</span>
        </div>
        <!-- ZOOM + -->
        <div mat-menu-item (click)="zoom(+1)">
            <mat-icon color="primary">add_circle_outline</mat-icon>
            <span translate>MAIN_MENU_VIEW-ZOOM-IN</span>
        </div>
        <!-- ZOOM - -->
        <div mat-menu-item (click)="zoom(-1)">
            <mat-icon color="primary">remove_circle_outline</mat-icon>
            <span translate>MAIN_MENU_VIEW-ZOOM-OUT</span>
        </div>
        <hr />
        <!-- APRI NODI -->
        <div mat-menu-item (click)="setFoldingState(true)">
            <mat-icon color="primary" svgIcon="open_node"></mat-icon>
            <span translate>MAIN_MENU_VIEW-OPEN-NODES</span>
        </div>
        <!-- CHIUDI NODI -->
        <div mat-menu-item (click)="setFoldingState(false)">
            <mat-icon color="primary" svgIcon="hide_node"></mat-icon>
            <span translate>MAIN_MENU_VIEW-CLOSE-NODES</span>
        </div>
        <!-- GRIGLIA -->
        <div mat-menu-item (click)="setGrid()">
            <mat-icon color="primary" [ngClass]="{'grid-mode ': isGridVisible}" fontSet="material-icons-outlined">grid_3x3</mat-icon>
            <span translate>MAIN_MENU_VIEW-GRID</span>
        </div>
        <hr />
        <!-- CERCA NELLA MAPPA -->
        <div mat-menu-item (click)="findInMap()">
            <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">find_in_page</mat-icon>
            <span translate>MAIN_TOOLBAR_FIND</span>
        </div>
        <!-- CERCA NEL WEB -->
        <button mat-menu-item (click)="searchOnInternet()">
            <mat-icon color="primary" fontSet="material-icons-outlined">travel_explore</mat-icon>
            <span translate>MAIN_TOOLBAR_SEARCHWEB</span>
        </button>
        <!-- CHAT -->
        <div *ngIf="!isMobileMode" mat-menu-item (click)="openChat()">
            <mat-icon color="primary" svgIcon="account-details"></mat-icon>
            <span translate>MAIN_TOOLBAR_CHAT</span>
        </div>
        <!-- EXTRA -->
        <div *ngIf="!isMobileMode || isDevEnvOrLocalHost" mat-menu-item [matMenuTriggerFor]="extra">
            <mat-icon color="primary">assistant</mat-icon>
            <span translate>MAIN_TOOLBAR_EXTRA</span>
        </div>
        <mat-menu #extra="matMenu">
           
                <!-- OUTLINE -->
                <div mat-menu-item *ngIf="isOutlineEnabled" (click)="openOutline()">
                    <mat-icon color="primary" fontSet="material-icons-outlined">list_alt</mat-icon>
                    <span translate>MAIN_TOOLBAR_OUTLINE</span>
                </div>
                <!-- APPUNTI -->
                <div mat-menu-item (click)="openBlockNotes()">
                    <mat-icon *ngIf="!hasNotes" color="primary" fontSet="material-icons-outlined">note_add</mat-icon>
                    <mat-icon *ngIf="hasNotes" color="primary" class="material-icons-sharp" fontSet="material-icons-filled">task</mat-icon>
                    <span translate>DOC_NOTES</span>
                </div>
                <!-- PDF -->
                <div mat-menu-item *ngIf="isPdfEnabled" (click)="openPdf()">
                    <mat-icon color="primary" class="material-icons-sharp" fontSet="material-icons-filled">picture_as_pdf</mat-icon>
                    <span translate>MAIN_TOOLBAR_PDF</span>
                </div>
           
        </mat-menu>
   
</mat-menu>
<!-- MENU INSERISCI -->
<mat-menu #insertMenu="matMenu" [overlapTrigger]="false">
   
        <!-- NODO -->
        <div mat-menu-item (click)="addNode()">
            <mat-icon color="primary" svgIcon="add-node"></mat-icon>
            <span translate>MAIN_MENU_INSERT-NODE</span>
        </div>
        <!-- TABELLA -->
        <div mat-menu-item (click)="addTable()">
            <mat-icon color="primary" svgIcon="table-plus"></mat-icon>
            <span translate>MAIN_MENU_INSERT-TABLE</span>
        </div>
        <!-- TITOLO NODO -->
        <div mat-menu-item (click)="editText()">
            <mat-icon color="primary" svgIcon="square-edit-outline"></mat-icon>
            <span translate>MAIN_MENU_EDIT-TEXT</span>
        </div>
        <!-- IMMAGINI -->
        <div [disabled]="!isSingleNodeSelected" mat-menu-item [matMenuTriggerFor]="insertImage">
            <mat-icon color="primary" svgIcon="image-plus"></mat-icon>
            <span translate>IMAGE</span>
        </div>
        <mat-menu #insertImage="matMenu">
           
                <app-image-menu></app-image-menu>
           
        </mat-menu>
        <!-- APPROFONDIMENTO -->
        <div [disabled]="!isSingleNodeSelected" mat-menu-item (click)="editDeep()">
            <mat-icon color="primary" svgIcon="add-deep"></mat-icon>
            <span translate>MAIN_MENU_INSERT-DEEPTEXT</span>
        </div>
        <!-- MATEMATICA -->
        <div *ngIf="isLab" [disabled]="!isMathEnabled || (!isSingleNodeSelected && isSelectionPresent)" mat-menu-item (click)="editMath()">
            <mat-icon color="primary" fontSet="material-icons-outlined">functions</mat-icon>
            <span translate>INSERT_MATH</span>
        </div>
        <!-- ALLEGATO -->
        <div [disabled]="!isSingleNodeSelected" mat-menu-item [matMenuTriggerFor]="linksMenu">
            <mat-icon color="primary" fontSet="material-icons-outlined">attach_file</mat-icon>
            <span translate>ATTACHMENT</span>
        </div>
        <mat-menu #linksMenu="matMenu" [overlapTrigger]="false">
           
                <div mat-menu-item (click)="editLink()" [disabled]="!nodeSelected">
                    <mat-icon color="primary" fontSet="material-icons-outlined">link</mat-icon>
                    <span translate>MAIN_MENU_INSERT-LINK</span>
                </div>
                <!-- <div *ngIf="isGoogleUser" mat-menu-item (click)="editLinkGoogleDrive('map')" [disabled]="!nodeSelected">
                    <mat-icon color="primary" svgIcon="sitemap"></mat-icon>
                    <span translate>MAIN_MENU_INSERT-LINK-MAP</span>
                </div> -->
                <div *ngIf="isGoogleUser" mat-menu-item (click)="editLinkGoogleDrive('drive')" [disabled]="!nodeSelected">
                    <mat-icon color="primary" svgIcon="google-drive"></mat-icon>
                    <span translate>MAIN_MENU_INSERT-LINK-GOOGLE-DRIVE</span>
                </div>
           
        </mat-menu>
        <!-- CERCA CONTENUTI NEL WEB -->
        <div mat-menu-item (click)="searchOnWeb('google')">
            <mat-icon color="primary" fontSet="material-icons-outlined">travel_explore</mat-icon>
            <span translate>SEARCH_ON_WEB</span>
        </div>
   
</mat-menu>
<!-- MENU STRUMENTI -->
<mat-menu #toolsMenu="matMenu" [overlapTrigger]="false">
   
        <!-- FUMETTO -->
        <div mat-menu-item (click)="TTSRead()">
            <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">sms</mat-icon>
            <span translate>MAIN_TOOLBAR_TEXT-TO-SPEECH</span>
        </div>
        <!-- INSERIMENTO RAPIDO -->
        <div mat-menu-item (click)="quickEdit()">
            <mat-icon color="primary" fontSet="material-icons-outlined">rtt</mat-icon>
            <span translate>MAIN_MENU_INSERT-QUICKEDIT</span>
        </div>
        <!-- INSERIMENTO VOCALE -->
        <div *ngIf="!deviceService.isMobileOrTabletDevice" mat-menu-item (click)="speechEdit()">
            <mat-icon color="primary">settings_voice</mat-icon>
            <span translate>MAIN_MENU_INSERT-QUICKSPEECH</span>
        </div>
        <!-- INSERIMENTO VOCALE -->
        <div mat-menu-item (click)="arrangeMap()">
            <mat-icon color="primary" svgIcon="arrange-map"></mat-icon>
            <span translate>GENERIC_TOOLBAR_ARRANGE</span>
        </div>
   
</mat-menu>
<!-- MENU FORMATO -->
<mat-menu #formatMenu="matMenu" [overlapTrigger]="false">
   
        <div mat-menu-item [matMenuTriggerFor]="formatTextMenu" [disabled]="error || noSelection">
            <!-- TEXT -->
            <mat-icon color="primary" fontSet="material-icons-outlined">text_format</mat-icon>
            <span translate>MAIN_MENU_FORMAT-TEXT</span>
        </div>
        <mat-menu #formatTextMenu="matMenu" [overlapTrigger]="false">
           
                <div mat-menu-item [matMenuTriggerFor]="formatTextEffectsMenu" [disabled]="error || noSelection">
                    <!-- EFFECTS-->
                    <span class="menu-item-name-spacer" translate>MAIN_MENU_FORMAT-TEXT-EFFECTS</span>
                </div>
                <mat-menu #formatTextEffectsMenu="matMenu" [overlapTrigger]="false">
                   
                        <div mat-menu-item (click)="textBold()" [disabled]="error || noSelection">
                            <mat-icon *ngIf="isBold" color="accent">check</mat-icon>
                            <span class="bold" translate>MAIN_MENU_FORMAT-TEXT-EFFECTS-BOLD</span>
                        </div>
                        <div mat-menu-item (click)="textItalic()" [disabled]="error || noSelection">
                            <mat-icon *ngIf="isItalic" color="accent">check</mat-icon>
                            <span class="italic" translate>MAIN_MENU_FORMAT-TEXT-EFFECTS-ITALIC</span>
                        </div>
                        <div mat-menu-item (click)="textUnderline()" [disabled]="error || noSelection">
                            <mat-icon *ngIf="isUnderline" color="accent">check</mat-icon>
                            <span class="underline" translate>MAIN_MENU_FORMAT-TEXT-EFFECTS-UNDERLINE</span>
                        </div>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="formatTextFontMenu" [disabled]="error || noSelection">
                    <!--<mat-icon color="primary" svgIcon="format-font"></mat-icon>-->
                    <!--SE SI VUOLE CHE IL TESTO CAMBI ANCHE IL FONT IN BASE ALLA SELEZIONE METTERE QUESTA DIRETTIVA NELLO SPAN [style.font-family]="fontFamily"-->
                    <span class="font-span">Aa</span>
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONT</span>
                </div>
                <mat-menu #formatTextFontMenu="matMenu" [overlapTrigger]="false">
                   
                        <div *ngFor="let font of fontFamilies" mat-menu-item (click)="changeFontFamily(font.name)" [disabled]="error || noSelection">
                            <mat-icon color="accent" *ngIf="fontFamily === font.name">check</mat-icon>
                            <span [ngClass]="fontFamily === font.name ? '' : 'menu-item-name-spacer'" [style.font-family]="font.name">{{font.name}}</span>
                        </div>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="formatTextFontSizeMenu" [disabled]="error || noSelection">
                    <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">format_size</mat-icon>
                    <span translate>MAIN_MENU_FORMAT-TEXT-FONTSIZE</span>
                </div>
                <mat-menu #formatTextFontSizeMenu="matMenu" [overlapTrigger]="false">
                   
                        <div *ngFor="let size of fontSizes" mat-menu-item (click)="changeFontSize(size.value)" [disabled]="error || noSelection">
                            <mat-icon color="accent" *ngIf="selectedFontSize === size.value">check</mat-icon>
                            <span [ngClass]="selectedFontSize === size.value ? '':'menu-item-name-spacer'">{{size.value}}</span>
                        </div>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="colorFontMenu">
                    <mat-icon color="primary" class="material-icons-sharp" fontSet="material-icons-filled">format_color_text</mat-icon>
                    <span translate>MAIN_MENU_FILE-TEXT-COLOR</span>
                </div>
                <mat-menu class="color-picker-container" #colorFontMenu="matMenu" [overlapTrigger]="false">
                   
                        <app-menu-color-picker [usedColorLabel]="usedColorLabel" [selectedColor]="selectedFontColor" (onChangeColor)="changeFontColor($event)" [colors]="baseColors" [showTransparent]="false"></app-menu-color-picker>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="colorFontBackMenu">
                    <mat-icon color="primary" svgIcon="marker"></mat-icon>
                    <span translate>MAIN_MENU_FILE-TEXT-BACKCOLOR</span>
                </div>
                <mat-menu class="color-picker-container" #colorFontBackMenu="matMenu" [overlapTrigger]="false">
                   
                        <app-menu-color-picker [usedColorLabel]="usedColorLabel" [selectedColor]="selectedFontBackColor" (onChangeColor)="changeColorFontBack($event)" [colors]="highlightColors"></app-menu-color-picker>
                   
                </mat-menu>

                <!-- <app-button-color-picker [pickerIcon]="highlightIcon" matTooltip="{{'FORMAT_NODE_TOOLBAR-HIGHLIGHT' | translate}}" [usedColorLabel]="colorUsedLabel" [selectedColor]="selectedHighlightColor" (onChangeColor)="changeHighlightColor($event)" [colors]="colors"></app-button-color-picker> -->

                <div mat-menu-item [matMenuTriggerFor]="fileSelectionLanguageMenu" [disabled]="error || noSelection">
                    <span class="menu-item-name-spacer" translate>MAIN_MENU_FORMAT-TEXT-LANGUAGE</span>
                </div>
                <mat-menu #fileSelectionLanguageMenu="matMenu" [overlapTrigger]="false">
                   
                        <div class="language-menu-item-container" *ngFor="let l of languages" mat-menu-item (click)="changeSelectionLanguage(l)">
                            <img [src]="l.icon" class="language-icon" />
                            <span translate>{{l.viewValue}}</span>
                        </div>
                   
                </mat-menu>
           
        </mat-menu>

        <div mat-menu-item [disabled]="true" *ngIf="!nodeSelected">
            <!-- NODE (DISABLED) -->
            <mat-icon color="primary" fontSet="material-icons-outlined">aspect_ratio</mat-icon>
            <span translate>MAIN_MENU_FORMAT-NODE</span>
        </div>
        <div mat-menu-item [matMenuTriggerFor]="formatNodeMenu" *ngIf="nodeSelected">
            <!-- NODE -->
            <mat-icon color="primary" fontSet="material-icons-outlined">aspect_ratio</mat-icon>
            <span translate>MAIN_MENU_FORMAT-NODE</span>
        </div>
        <mat-menu #formatNodeMenu="matMenu" [overlapTrigger]="false">
           
                <div mat-menu-item [matMenuTriggerFor]="colorMenu" [disabled]="!nodeSelected">
                    <mat-icon color="primary" fontSet="material-icons-outlined">format_color_fill</mat-icon>
                    <span translate>MAIN_MENU_FORMAT-NODE-BACKCOLOR</span>
                </div>
                <mat-menu class="color-picker-container" #colorMenu="matMenu">
                   
                        <app-menu-color-picker [disabled]="!nodeSelected" [usedColorLabel]="usedColorLabel" [selectedColor]="selectedFontColor" (onChangeColor)="changeSelectionBackcolor($event)" [colors]="baseColors"></app-menu-color-picker>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="colorBorderMenu" [disabled]="!nodeSelected">
                    <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">edit</mat-icon>
                    <span translate>MAIN_MENU_FORMAT-NODE-COLOR</span>
                </div>
                <mat-menu class="color-picker-container" #colorBorderMenu="matMenu">
                   
                        <app-menu-color-picker [disabled]="!nodeSelected" [usedColorLabel]="usedColorLabel" (onChangeColor)="changeSelectionColor($event)" [colors]="baseColors"></app-menu-color-picker>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="formatNodeShapeMenu" [disabled]="noShapedNodeSelected">
                    <mat-icon color="primary" svgIcon="node-shape"></mat-icon>
                    <span translate>MAIN_MENU_FORMAT-NODE-SHAPE</span>
                </div>
                <mat-menu #formatNodeShapeMenu="matMenu" [overlapTrigger]="false">
                   
                        <div *ngFor="let ns of nodeShapes" mat-menu-item (click)="changeNodeShape(ns.value)" [disabled]="noNodeSelected">
                            <mat-icon color="primary" [svgIcon]="ns.icon"></mat-icon>
                            <span translate>{{ns.viewValue}}</span>
                        </div>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="formatNodeWidthMenu" [disabled]="!nodeSelected">
                    <mat-icon color="primary" fontSet="material-icons-outlined">line_weight</mat-icon>
                    <span translate>MAIN_MENU_FORMAT-NODE-THICKNESS</span>
                </div>
                <mat-menu #formatNodeWidthMenu="matMenu" [overlapTrigger]="false">
                   
                        <div *ngFor="let b of bordersWidth" mat-menu-item (click)="changeBorderWidth(b.value)" [disabled]="!nodeSelected">
                            <mat-icon color="primary" [svgIcon]="b.icon"></mat-icon>
                            <span>{{b.viewValue}}</span>
                        </div>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="formatNodeTextPosMenu" [disabled]="!nodeSelected">
                    <mat-icon color="primary" svgIcon="text-position"></mat-icon>
                    <span translate>MAIN_MENU_FORMAT-NODE-TEXTLAYOUT</span>
                </div>
                <mat-menu #formatNodeTextPosMenu="matMenu" [overlapTrigger]="false">
                   
                        <div mat-menu-item *ngFor="let tp of textPositions" (click)="changeTextPosition(tp.value)" [disabled]="!nodeSelected">
                            <mat-icon color="primary" [svgIcon]="tp.icon"></mat-icon>
                            <span translate>{{tp.viewValue}}</span>
                        </div>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="deleteMenu">
                    <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">delete</mat-icon>
                    <span translate>DELETE</span>
                </div>
                <mat-menu #deleteMenu="matMenu" [overlapTrigger]="false">
                   
                        <!-- Delete deep text -->
                        <div mat-menu-item [disabled]="!isDeepTextPresent" (click)="deleteDeepText()">
                            <mat-icon color="primary" svgIcon="tooltip-text"></mat-icon>
                            <span translate>DELETE_DEEPTEXT</span>
                        </div>
                        <!-- Delete image -->
                        <div mat-menu-item [disabled]="!imageSelected" (click)="removeImage()">
                            <mat-icon color="primary" fontSet="material-icons-filled" class="material-icons-sharp">image</mat-icon>
                            <span translate>DELETE_IMAGE</span>
                        </div>

                        <!-- Delete link or video -->
                        <div mat-menu-item *ngIf="!mainLinkIsVideo" [disabled]="!isLinkPresent" (click)="deleteLink()">
                            <mat-icon color="primary" fontSet="material-icons-outlined">link</mat-icon>
                            <span translate>DELETE_LINK</span>
                        </div>
                        <div mat-menu-item *ngIf="mainLinkIsVideo" [disabled]="!isLinkPresent" (click)="deleteLink()">
                            <mat-icon color="primary" svgIcon="youtube"></mat-icon>
                            <span translate>DELETE_VIDEO</span>
                        </div>

                        <!-- Delete title text -->
                        <div mat-menu-item [disabled]="!isTitleTextPresent" (click)="deleteText()">
                            <mat-icon color="primary" svgIcon="alphabetical"></mat-icon>
                            <span translate>DELETE_TEXT</span>
                        </div>

                   
                </mat-menu>
                <div mat-menu-item (click)="cropImage()" [disabled]="!imageSelected">
                    <mat-icon color="primary" svgIcon="image-edit-outline"></mat-icon>
                    <span translate>EDIT_IMAGE</span>
                </div>
                <div mat-menu-item (click)="toggleFrameImage()" [disabled]="!imageSelected">
                    <mat-icon color="primary" fontSet="material-icons-outlined">image</mat-icon>
                    <span translate>MAIN_MENU_FORMAT-NODE-IMAGEFRAME</span>
                </div>
           
        </mat-menu>

        <div mat-menu-item [disabled]="true" *ngIf="!edgeSelected">
            <!-- EDGE (DISABLED) -->
            <mat-icon color="primary">call_made</mat-icon>
            <span translate>MAIN_MENU_FORMAT-ARROW</span>
        </div>
        <div mat-menu-item [matMenuTriggerFor]="formatEdgeMenu" *ngIf="edgeSelected">
            <!-- EDGE -->
            <mat-icon color="primary">call_made</mat-icon>
            <span translate>MAIN_MENU_FORMAT-ARROW</span>
        </div>
        <mat-menu #formatEdgeMenu="matMenu" [overlapTrigger]="false">
           
                <div mat-menu-item [matMenuTriggerFor]="colorHighligthMenu" [disabled]="!edgeSelected">
                    <mat-icon color="primary" mat-icon>edit</mat-icon>
                    <span translate>MAIN_MENU_FORMAT-ARROW-COLOR</span>
                </div>
                <mat-menu class="color-picker-container" #colorHighligthMenu="matMenu">
                   
                        <app-menu-color-picker [usedColorLabel]="usedColorLabel" [selectedColor]="selectedFontColor" (onChangeColor)="changeSelectionColor($event)" [colors]="baseColors" [disabled]="!edgeSelected"></app-menu-color-picker>
                   
                </mat-menu>
                <div mat-menu-item [matMenuTriggerFor]="formatEdgeWidthMenu" [disabled]="!edgeSelected">
                    <mat-icon color="primary" fontSet="material-icons-outlined">line_weight</mat-icon>
                    <span translate>MAIN_MENU_FORMAT-ARROW-THICKNESS</span>
                </div>
                <mat-menu #formatEdgeWidthMenu="matMenu" [overlapTrigger]="false">
                   
                        <div *ngFor="let b of bordersWidth" mat-menu-item (click)="changeBorderWidth(b.value)" [disabled]="!edgeSelected">
                            <mat-icon color="primary" [svgIcon]="b.icon"></mat-icon>
                            <span>{{b.viewValue}}</span>
                        </div>
                   
                </mat-menu>
                <div mat-menu-item (click)="setSelectedEdgesTerminals('right')" [disabled]="!edgeSelected">
                    <mat-icon color="primary" fontSet="material-icons-outlined">east</mat-icon>
                    <span translate>MAIN_MENU_FORMAT-ARROW-RIGHTARROW</span>
                </div>
                <div mat-menu-item (click)="setSelectedEdgesTerminals('left')" [disabled]="!edgeSelected">
                    <mat-icon color="primary" fontSet="material-icons-outlined">west</mat-icon>
                    <span translate>MAIN_MENU_FORMAT-ARROW-LEFTARROW</span>
                </div>
                <div mat-menu-item (click)="breakEdge()" [disabled]="!edgeSelected">
                    <mat-icon color="primary" svgIcon="ray-vertex"></mat-icon>
                    <span translate>MAIN_MENU_FORMAT-ARROW-BREAKARROW</span>
                </div>
                <div mat-menu-item [matMenuTriggerFor]="edgeType">
                    <mat-icon color="primary" fontSet="material-icons-outlined">north_east</mat-icon>
                    <span color="primary" translate>FORMAT_NODE_TOOLBAR-CURVE-EDGE</span>
                </div>
                <mat-menu #edgeType="matMenu" color="primary" [overlapTrigger]="false">
                   
                        <div mat-menu-item (click)="curvedEdge(false)">
                            <mat-icon fontSet="material-icons-outlined" color="primary">east</mat-icon>
                            <span color="primary" translate>EDGE_STRAIGHT</span>
                        </div>
                        <div mat-menu-item (click)="curvedEdge(true)">
                            <mat-icon fontSet="material-icons-outlined" color="primary">redo</mat-icon>
                            <span color="primary" translate>EDGE_CURVE</span>
                        </div>
                   
                </mat-menu>

                <div mat-menu-item [disabled]="true" *ngIf="!isTableSelected">
                    <!-- TABLE (DISABLED) -->
                    <mat-icon color="primary" svgIcon="table"></mat-icon>
                    <span translate>MAIN_MENU_FORMAT-TABLE</span>
                </div>
                <div mat-menu-item [matMenuTriggerFor]="formatTableMenu" *ngIf="isTableSelected">
                    <!-- TABLE -->
                    <mat-icon color="primary" svgIcon="table"></mat-icon>
                    <span translate>MAIN_MENU_FORMAT-TABLE</span>
                </div>
                <mat-menu #formatTableMenu="matMenu" [overlapTrigger]="false">
                   
                        <div mat-menu-item [disabled]="isTableLocked" (click)="removeTable()">
                            <mat-icon color="primary" svgIcon="grid-off"></mat-icon>
                            <span translate>TABLE_TOOLBAR-REMOVE-TABLE</span>
                        </div>
                        <div mat-menu-item [disabled]="isTableLocked" (click)="removeRow()">
                            <mat-icon color="primary" svgIcon="table-row-remove"></mat-icon>
                            <span translate>TABLE_TOOLBAR-REMOVE-ROW</span>
                        </div>
                        <div mat-menu-item [disabled]="isTableLocked" (click)="removeCol()">
                            <mat-icon color="primary" svgIcon="remove-column"></mat-icon>
                            <span translate>TABLE_TOOLBAR-REMOVE-COL</span>
                        </div>
                        <div mat-menu-item [disabled]="isTableLocked" (click)="addTableRowUp()">
                            <mat-icon color="primary" svgIcon="table-row-plus-before"></mat-icon>
                            <span translate>TABLE_TOOLBAR-ADD-ROW-ABOVE</span>
                        </div>
                        <div mat-menu-item [disabled]="isTableLocked" (click)="addTableRowDown()">
                            <mat-icon color="primary" svgIcon="table-row-plus-after"></mat-icon>
                            <span translate>TABLE_TOOLBAR-ADD-ROW-BELOW</span>
                        </div>
                        <div mat-menu-item [disabled]="isTableLocked" (click)="addTableColumnLeft()">
                            <mat-icon color="primary" svgIcon="insert-column-sx"></mat-icon>
                            <span translate>TABLE_TOOLBAR-ADD-COL-BEFORE</span>
                        </div>
                        <div mat-menu-item [disabled]="isTableLocked" (click)="addTableColumnRight()">
                            <mat-icon color="primary" svgIcon="insert-column-dx"></mat-icon>
                            <span translate>TABLE_TOOLBAR-ADD-COL-AFTER</span>
                        </div>
                        <div mat-menu-item (click)="lockTable(true)" *ngIf="!isTableLocked">
                            <mat-icon color="primary" svgIcon="lock-open"></mat-icon>
                            <span translate>TABLE_TOOLBAR-LOCK</span>
                        </div>
                        <div mat-menu-item (click)="lockTable(false)" *ngIf="isTableLocked">
                            <mat-icon class="icon-red">lock</mat-icon>
                            <span translate>TABLE_TOOLBAR-UNLOCK</span>
                        </div>
                   
                </mat-menu>
           
        </mat-menu>
   
</mat-menu>
<!-- MENU DISPONI -->
<mat-menu #arrangeMenu="matMenu" [overlapTrigger]="false">
   
        <!-- ORDER (DISABLED) -->
        <div mat-menu-item [disabled]="true" *ngIf="!canOrder">
            <mat-icon color="primary">swap_vert</mat-icon>
            <span translate>MAIN_MENU_ARRANGE-ORDER</span>
        </div>
        <!-- ORDER -->
        <div mat-menu-item [matMenuTriggerFor]="arrangeOrderMenu" *ngIf="canOrder">
            <mat-icon color="primary">swap_vert</mat-icon>
            <span translate>MAIN_MENU_ARRANGE-ORDER</span>
        </div>
        <mat-menu #arrangeOrderMenu="matMenu" [overlapTrigger]="false">
           
                <div mat-menu-item (click)="bringSelectionToFront()">
                    <mat-icon color="primary" svgIcon="arrange-bring-forward"></mat-icon>
                    <span translate>MAIN_MENU_ARRANGE-ORDER-BRINGTOFRONT</span>
                </div>
                <div mat-menu-item (click)="sendSelectionToBack()">
                    <mat-icon color="primary" svgIcon="arrange-send-backward"></mat-icon>
                    <span translate>MAIN_MENU_ARRANGE-ORDER-SENDTOBACK</span>
                </div>
           
        </mat-menu>

        <!-- ALIGN (DISABLED)-->
        <div mat-menu-item [disabled]="true" *ngIf="!canAlign">
            <mat-icon color="primary" fontSet="material-icons-outlined">align_horizontal_left</mat-icon>
            <span translate>MAIN_MENU_ARRANGE-ALIGN</span>
        </div>
        <!-- ALIGN -->
        <div mat-menu-item [matMenuTriggerFor]="arrangeAlignMenu" *ngIf="canAlign">
            <mat-icon color="primary" fontSet="material-icons-outlined">align_horizontal_left</mat-icon>
            <span translate>MAIN_MENU_ARRANGE-ALIGN</span>
        </div>
        <mat-menu #arrangeAlignMenu="matMenu" [overlapTrigger]="false">
           
                <div *ngFor="let al of alignments" mat-menu-item (click)="changeAlign(al.value)">
                    <mat-icon [svgIcon]="al.icon" color="primary"></mat-icon>
                    <span translate>{{al.viewValue}}</span>
                </div>
           
        </mat-menu>

        <!-- SIZE (DISABLED) -->
        <div mat-menu-item [disabled]="true" *ngIf="!canResize">
            <mat-icon color="primary" fontSet="material-icons-outlined">tab_unselected</mat-icon>
            <span translate>MAIN_MENU_ARRANGE-SIZE</span>
        </div>
        <!-- SIZE -->
        <div mat-menu-item [matMenuTriggerFor]="arrangeSizeMenu" *ngIf="canResize">
            <mat-icon color="primary" fontsdSet="material-icons-outlined">tab_unselected</mat-icon>
            <span translate>MAIN_MENU_ARRANGE-SIZE</span>
        </div>
        <mat-menu #arrangeSizeMenu="matMenu" [overlapTrigger]="false">
           
                <div *ngFor="let s of sizes" mat-menu-item (click)="changeSize(s.value)">
                    <mat-icon [svgIcon]="s.icon" color="primary"></mat-icon>
                    <span translate>{{s.viewValue}}</span>
                </div>
           
        </mat-menu>
   
</mat-menu>
